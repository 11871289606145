<template>
    <div class="container-fluid">
        <div class="row">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div class="header-title">
                        <h4 class="card-title">Novo fornecedor</h4>
                    </div>
                </div>
                <div class="card-body">
                    <div class="new-user-info">
                        <form @submit.prevent="onSubmit()">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="fname">Data:</label>
                                    <input 
                                        type="date" 
                                        v-model="paymentDetail.Data" 
                                        class="form-control"
                                        :class="`form-control ${$v.paymentDetail.Data.$error ? 'is-invalid' : ''}`" 
                                        id="date" 
                                        placeholder="Data"
                                        @input="$v.paymentDetail.Data.$touch()" 
                                        @blur="$v.paymentDetail.Data.$touch()"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="phoneNumber">Entidade:</label>
                                    <input 
                                        type="text" 
                                        v-model="paymentDetail.Entidade" 
                                        class="form-control"
                                        :class="`form-control ${$v.paymentDetail.Entidade.$error ? 'is-invalid' : ''}`" 
                                        id="entity" 
                                        placeholder="Entidade"
                                        @input="$v.paymentDetail.Entidade.$touch()" 
                                        @blur="$v.paymentDetail.Entidade.$touch()"
                                    >
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="email">Valor:</label>
                                    <input 
                                        type="text" 
                                        v-model="paymentDetail.Valor" 
                                        class="form-control"
                                        :class="`form-control ${$v.paymentDetail.Valor.$error ? 'is-invalid' : ''}`" 
                                        id="value" 
                                        placeholder="Valor"
                                        @input="$v.paymentDetail.Valor.$touch()" 
                                        @blur="$v.paymentDetail.Valor.$touch()"
                                    >
                                </div>                          
                                <div class="form-group col-md-6">
                                    <label for="email">Iban:</label>
                                    <input 
                                        type="text" 
                                        v-model="paymentDetail.IBAN" 
                                        class="form-control"
                                        :class="`form-control ${$v.paymentDetail.IBAN.$error ? 'is-invalid' : ''}`" 
                                        id="iban" 
                                        placeholder="Iban"
                                        @input="$v.paymentDetail.IBAN.$touch()" 
                                        @blur="$v.paymentDetail.IBAN.$touch()"
                                    >
                                </div>                          
                                <div class="form-group col-md-6">
                                    <label for="email">Referência:</label>
                                    <input 
                                        type="text" 
                                        v-model="paymentDetail.Referência" 
                                        class="form-control"
                                        :class="`form-control ${$v.paymentDetail.Referência.$error ? 'is-invalid' : ''}`" 
                                        id="reference" 
                                        placeholder="Referência"
                                        @input="$v.paymentDetail.Referência.$touch()" 
                                        @blur="$v.paymentDetail.Referência.$touch()"
                                    >
                                </div>                          
                                <div class="form-group col-md-6">
                                    <label for="email">Estado:</label>
                                    <input 
                                        type="text" 
                                        v-model="paymentDetail.Estado" 
                                        class="form-control"
                                        :class="`form-control ${$v.paymentDetail.Estado.$error ? 'is-invalid' : ''}`" 
                                        id="state" 
                                        placeholder="Estado"
                                        @input="$v.paymentDetail.Estado.$touch()" 
                                        @blur="$v.paymentDetail.Estado.$touch()"
                                    >
                                </div>                          
                                <div class="form-group col-md-6">
                                    <label for="email">Fatura:</label>
                                    <input 
                                        type="text" 
                                        v-model="paymentDetail.Fatura" 
                                        class="form-control"
                                        :class="`form-control ${$v.paymentDetail.Fatura.$error ? 'is-invalid' : ''}`" 
                                        id="invoice" 
                                        placeholder="Fatura"
                                        @input="$v.paymentDetail.Fatura.$touch()" 
                                        @blur="$v.paymentDetail.Fatura.$touch()"
                                    >
                                </div>                          
                                <div class="form-group col-md-6">
                                    <label for="email">Pagamento:</label>
                                    <input 
                                        type="text" 
                                        v-model="paymentDetail.Pagamento" 
                                        class="form-control"
                                        :class="`form-control ${$v.paymentDetail.Pagamento.$error ? 'is-invalid' : ''}`" 
                                        id="payment" 
                                        placeholder="Pagamento"
                                        @input="$v.paymentDetail.Pagamento.$touch()" 
                                        @blur="$v.paymentDetail.Pagamento.$touch()"
                                    >
                                </div>                                               
                            </div>
                            <hr>
                            <button type="submit" class="btn btn-primary">Atualizar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
     </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
export default {
    name:'PaymentEdit',
    mixins: [validationMixin],
    validations: {
        paymentDetail: {
            Data: {required},
            Entidade: { required },
            Valor:{ required },
            IBAN:{ required },
            Referência: {required},
            Estado: {required},
            Fatura: {required},
            Pagamento: {required}
        }
    },
    data() {
        return {
            paymentDetail: {},
            payments: JSON.parse(localStorage.getItem('payments'))
        }
    },
    mounted () {
      if(this.$route.params.payment) {
        this.paymentDetail = this.$route.params.payment;
      }
      else{
        this.$router.push({name:'app.payments-list'});
      }
   },
    methods:{
        onSubmit()
        {
            let payments = JSON.parse(localStorage.getItem('payments'));
            this.$v.$touch()
            if (!this.$v.$invalid) {
                for(let index in payments) {
                    if(payments[index].id === this.paymentDetail.id) {
                        payments[index] = {
                            Data: this.paymentDetail.Data, 
                            Entidade: this.paymentDetail.Entidade, 
                            Valor: this.paymentDetail.Valor, 
                            IBAN: this.paymentDetail.IBAN, 
                            Referência: this.paymentDetail.Referência, 
                            Estado: this.paymentDetail.Estado,
                            Fatura: this.paymentDetail.Fatura,
                            Pagamento: this.paymentDetail.Pagamento
                        }
                        localStorage.setItem('payments', JSON.stringify(payments));
                        break;
                    }
                }
                this.$router.push({name:'app.payments-list'})
            }
        }
   }
}
</script>